import React, { useState, useEffect } from 'react';
import './App.css';
import backgroundImage from './background.gif';

const CountdownTimer = ({ targetDate }) => {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  function calculateTimeLeft() {
    const difference = +new Date(targetDate) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60)
      };
    }

    return timeLeft;
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  function addLeadingZero(num) {
    return num < 10 ? `0${num}` : num;
  }

  return (
    <div className="countdown">
      <div className="header">
        <div className="header-text">Insanity Gaming</div>
        <div className="countdown-text">LAUNCH COUNTDOWN</div>
      </div>
      <div className="timer-container">
        <div className="timer-box">
          <div className="timer-box-inner">
            <div className="time">{addLeadingZero(timeLeft.days || 0)}</div>
            <div className="label">Days</div>
          </div>
        </div>
        <div className="colon">:</div>
        <div className="timer-box">
          <div className="timer-box-inner">
            <div className="time">{addLeadingZero(timeLeft.hours || 0)}</div>
            <div className="label">Hours</div>
          </div>
        </div>
        <div className="colon">:</div>
        <div className="timer-box">
          <div className="timer-box-inner">
            <div className="time">{addLeadingZero(timeLeft.minutes || 0)}</div>
            <div className="label">Minutes</div>
          </div>
        </div>
        <div className="colon">:</div>
        <div className="timer-box">
          <div className="timer-box-inner">
            <div className="time">{addLeadingZero(timeLeft.seconds || 0)}</div>
            <div className="label">Seconds</div>
          </div>
        </div>
      </div>
      <div className="button-container">
        <div className="button-box">
          <div className="button-inner" onClick={() => window.location.href = 'https://discord.gg/'}>DISCORD</div>
        </div>
        <div className="button-box">
          <div className="button-inner" onClick={() => window.location.href = 'https://www.tiktok.com/'}>TIKTOK</div>
        </div>
      </div>
    </div>
  );
};

function App() {
  // Replace this date with your desired target date
  const targetDate = '2024-09-29 7:00:00';
  
  return (
    <div className="App" style={{backgroundImage: `url(${backgroundImage})`}}>
      <CountdownTimer targetDate={targetDate} />
    </div>
  );
}
const countdown = document.getElementById('countdown');
const content = document.getElementById('content');
const daysEl = document.getElementById('days');
const hoursEl = document.getElementById('hours');
const minutesEl = document.getElementById('minutes');
const secondsEl = document.getElementById('seconds');

const second = 1000;
const minute = second * 60;
const hour = minute * 60;
const day = hour * 24;

const countDownDate = new Date("May 30, 2023 00:00:00").getTime();

function updateCountdown() {
    const now = new Date().getTime();
    const distance = countDownDate - now;

    const days = Math.floor(distance / day);
    const hours = Math.floor((distance % day) / hour);
    const minutes = Math.floor((distance % hour) / minute);
    const seconds = Math.floor((distance % minute) / second);

    daysEl.innerHTML = days;
    hoursEl.innerHTML = hours;
    minutesEl.innerHTML = minutes;
    secondsEl.innerHTML = seconds;

    if (distance < 0) {
        clearInterval(countdownTimer);
        countdown.style.display = 'none';
        content.style.display = 'block';
    }
}

const countdownTimer = setInterval(updateCountdown, 1000);

// Tournament list
const tournaments = [
    { name: "Fortnite Cup", date: "June 15, 2023 18:00:00" },
    { name: "CS:GO Championship", date: "July 1, 2023 14:00:00" },
    { name: "Rocket League Tournament", date: "July 10, 2023 20:00:00" }
];

function updateTournamentCountdowns() {
    const tournamentList = document.getElementById('tournamentList');
    tournamentList.innerHTML = '';

    tournaments.forEach(tournament => {
        const tournamentDate = new Date(tournament.date).getTime();
        const now = new Date().getTime();
        const distance = tournamentDate - now;

        const days = Math.floor(distance / day);
        const hours = Math.floor((distance % day) / hour);
        const minutes = Math.floor((distance % hour) / minute);

        const listItem = document.createElement('li');
        listItem.innerHTML = `
            <span class="tournament-name">${tournament.name}</span><br>
            <span class="tournament-countdown">${days}d ${hours}h ${minutes}m</span>
        `;
        tournamentList.appendChild(listItem);
    });
}

updateTournamentCountdowns();
setInterval(updateTournamentCountdowns, 60000); // Update every minute

export default App;
